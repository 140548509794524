export const en = {
  navbar: {
    app_title: "SSR",
    messages: "Messages",
    notifications: "Notifications",
    empty_messages: "Nothing to show right now",
    search: "Search by: Name, Account, Card, Profile",
  },
  sidebar: {
    dashboard: "Dashboard",
    accounts: {
      accounts: "Accounts",
      subscribtions: "Subscribtions",
      online_sessions: "Online Sessions",
      add: "Add",
    },
    profiles: {
      profiles: "Profiles",
      all: "All",
      add: "Add",
    },
    cards: {
      cards: "Cards",
      groups: "Cards Groups",
      all: "All Cards",
      generate: "Generate Cards",
    },
    admins: "Admins",
    resellers: "Resellers",
    support_tickets: "Support Tickets",
    notifications: {
      notifications: "Notifications",
      auto: "Automatic Notifications",
      manual: "Manual Notifications",
      wa: "Whatsapp",
    },
    nas: "Nas",
    services: {
      services: "Services",
      mikrotik: "Mikrotik",
      support_tickets: "Support Tickets",
      bandwidth: "Bandwidth Control",
    },
    settings: {
      settings: "Settings",
      system_settings: "System Settings",
      backup: "Backup",
      restore: "Restore",
      update: "Updates",
      reset: "Factory Reset",
    },
    logout: "Logout",
    admins_log: "Operations Log",
    accounting: "Accounting",
    bills: "Bills",
    transactions: "Money transactions",
    sessions_log: "Sessions Log",
    reports: "Reports",
    admin_bills: "Admin Bills",
  },
  login: {
    title: "SUPER SPEED - Radius Manager",
    email: "Username",
    password: "Password",
    realm: "Realm",
    login: "Login",
    serial_number: "Enter License Serial Number:",
    license: "Activate",
  },
  dashboard: {
    dashboard: "Dashboard",
    statistics: {
      total_accounts: "Total Accounts",
      online_accounts: "Online Accounts",
      expired_accounts: "Expired Accounts",
      disabled_accounts: "Disabled Accounts",
      total_cards: "Total Cards",
      online_cards: "Online Cards",
      expired_cards: "Expired Cards",
      disabled_cards: "Disabled Cards",
      total_users: "Total Users",
      profiles: "Profiles",
      managers: "Managers",
      total_online: "Online Sessions",
      more: "View More",
      money: "Available Credit",
      expiring_accounts: "Accounts expiring soon",
      expiring_cards: "Cards expiring soon",
      support_tickets: "Support Tickets",
      debt_accounts: "Debted Accounts",
      expiration_profile_accounts: "Accounts at expiration profile",
    },
    time: {
      uptime: "Uptime",
      version: "Version",
    },
    system_usage: "System Usage",
    gauges: {
      memory: "Memory",
      cpu: "Cpu",
      disk: "Disk Usage",
      uptime: "Uptime",
      voltage: "Voltage",
      cpu_temp: "Cpu Temperature",
    },
    daily: {
      daily_users: "Daily Active Users",
    },
    logins: "Users Login",
    new_users: "New Users",
  },
  accounts: {
    id: "ID Number",
    account: "Account",
    accounts: "Accounts",
    auto_update: "Auto Update",
    add_account: "Add Account",
    edit_account: "Edit Account",
    first_name: "First Name",
    last_name: "Last Name",
    username: "Username",
    password: "Password",
    email: "Email",
    phone: "Mobile Phone Number",
    phone_line: "Line Phone Number",
    address: "Address",
    profile: "Profile",
    ip_address: "IP Address",
    expires_at: "Expires At",
    sessions: "Allowed Sessions",
    used_quota: "Used Quota",
    status: "Status",
    connection: "Connection",
    notes: "Notes",
    has_ip: "Static IP",
    has_ip_false: "Auto IP",
    has_expire_profile: "Expiration Profile",
    has_expire_profile_false: "NO Expiration Profile",
    has_fixed_expiration: "Expiration Date",
    has_fixed_expiration_false: "Auto expiration date",
    record_sessions: "Record Sessions",
    record_sessions_false: "Erase Sessions",
    expire_profile: "Expiration Profile",
    auto_renew: "Auto Renew",
    auto_renew_false: "Manual Renew",
    add: "Add",
    save: "Save",
    cancel: "Cancel",
    details: "Account Details",
    general: "General Information",
    full_name: "Full Name",
    last_seen: "Last Time Online",
    debts: "Debts",
    paid: "Paid",
    paid_false: "Not Paid",
    expired_since: "Expired Since",
    is_mac: "Allow Only Mac Address",
    mac_address: "Fixed Mac Address",
    paid_at: "Bill Paid At",
    next_profile: "Next Profile when original ends",
    serial_number: "Serial Number",
    wait_days: "Wait Days",
    reseller: "Reseller Responsible",
    money: {
      title: "Edit Credits",
      money: "Credits",
      current: "Current Credits",
      deposit: "Deposit",
      withdraw: "Withdraw",
    },
    temp_speed: {
      title: "Temporarily Change account speed",
      default_speed: "Default Speed",
      new_speed: "New Speed",
      note: "Temporary Speed will only apply until disconnect",
      apply: "Apply",
      session_time: "Session Time (Minutes)",
    },
    live_traffic: "Show Live Traffic",
    traffic: "Account Traffic Graph",
  },
  online: {
    online_customers: "Online Customers",
    auto_update: "Auto Update",
    username: "Username",
    name: "Name",
    profile: "Profile",
    mac_address: "MAC Address",
    ip: "IP",
    type: "Type",
    traffic_used: "Traffic Used",
    uptime: "Uptime",
    nas_name: "Connected Nas",
    interface: "Interface",
    from: "Source",
    start_date: "Start Time",
    stop_date: "Stop Time",
    stop_cause: "Stop Cause",
    sum: "Traffic Sum:",
    range_date: "Set date range",
    truncate: {
      title: "Clear Sessions",
      end_date: "Clear Sessions Before Date",
      only: "Clear Sessions for:",
      all: "Accounts & Cards",
      cards_only: "Cards Only",
      accounts_only: "Accounts Only",
      warning_cards:
        "Warning! Clearing not expired cards sessions will cause them to renable them.",
      truncate: "Confirm Clear",
      confirm: "Are you sure you want to clear sessions",
      before_3months: "Sessions must be at least 3 months from now",
    },
  },
  profiles: {
    profile: "Profile",
    auto_update: "Auto Update",
    name: "Name",
    enabled: "Enabled",
    disabled: "Disabled",
    service: "Service",
    type: "Type",
    price: "Price",
    speed_limit: "Speed Limit",
    expiration: "Expiration",
    quota: "Quota",
    ip_pool: "Mikrotik IP Pool",
    total_accounts: "Total Accounts",
    online_accounts: "Online Accounts",
    allowed_nases: "Allowed Nases",
    add_profile: "Add Profile",
    edit_profile: "Edit Profile",
    connection_speed: "Connection Speed:",
    download_speed: "Download Speed",
    upload_speed: "Upload Speed",
    expiration_date: "Has Expiration Date",
    expiration_date_false: "No Expiration Date",
    limited_quota: "Limited Quota",
    limited_quota_false: "Unlimited Quota",
    shared: "Shared Profile",
    shared_false: "Not Shared",
    shared_note:
      "Enabled this option add shared profile script to the mikrotik with shared value of:",
    shared_download_speed: "Shared Download",
    shared_upload_speed: "Shared Upload",
    custom_attributes: "Custom Attributes",
    resellers_allowed_sessions: "Allowed Devices (for Resellers)",
    open_speed: "Open Profile Speed",
    start_hour: "Start Time",
    stop_hour: "Stop Time",
    open_download: "Open Download Speed MB",
    open_upload: "Open Upload Speed MB",
    select_nas: "Select Nas",
    unlimited_speed: "Unlimited Speed",
    unlimited_speed_false: "Limited Speed",
    expiration_profile: "Expiration Profile",
    expiration_profile_note:
      "Enabling this option will add expiration pool & proxy to all allowed nases and show expiration message to the users",
    expiration_profile_message: "Expiration Message",
    next_profile: "Next Profile",
    unit: {
      unit: "Unit",
      days: "Days",
      months: "Months",
      hours: "Hours",
    },
    add: "Add",
    save: "Save",
    cancel: "Cancel",
    renew: {
      title: "Renew account profile",
      accounts_title: "Profile accounts fixed expiration",
      amount: "Profile",
      renew: "Renew",
      confirm: "Are you sure you want to renew to the visible date ?",
      accounts_confirm:
        "Are you sure you want to renew all profile accounts to the visible date ?",
      money_state: "Paid State",
      paid: "Paid",
      in_debt: "In debt",
      debt_confirm: "While cost is not paid ?",
      paid_debt_confirm: "Without paying the full price ?",
    },
    app_allowed: "Allowed to use in App",
    app_allowed_false: "Not Allowed to use in app",
    app_allowed_note: "Allowed to use in app for self renewing profile",
    change_profile_values: {
      details: "Changing profile details",
      old_price: "Current profile price",
      new_price: "New profile price",
      billed_days: "Days remaining until current profie expires",
      new_days: "New days Added",
      billed_days_price: "Cost of changing old profile days",
      new_days_price: "Cost of added days",
    },
  },
  batches: {
    auto_update: "Auto Update",
    add_cards: "Add Cards",
    name: "Name",
    quantity: "Quantity",
    price: "Price",
    prefix: "Prefix",
    profile: "Profile",
    username_length: "Username Length",
    password_length: "Password Length",
    sessions: "Allowed Sessions",
    created_at: "Created At",
    chars: "Characters Type",
    alpha: "Alphabets & Numbers",
    numeric: "Numbers Only",
    add: "Generate",
    save: "Save",
    cancel: "Cancel",
    print: "Print",
    print_cards: "Print Cards",
    logo: "Logo Image",
    network: "Network Name",
    horiz: "Horizontal Mode",
    cards_count: "Cards Count",
    cards_count_row: "Cards Count In Each Row",
    speed: "Speed",
    duration: "Duration",
    quota: "Quota",
    color_border: "Border",
    color_background: "Background",
    color_text_big: "Primary Text",
    color_text_small: "Secondary Text",
    color_input: "Primary Squares",
    color_boxes: "Secondary Squares",
    add_background: "Add Background",
    username_password: "Username & Password",
    truncate: "Remove Expired Cards",
    designer: "Design Type",
    basic_designer: "Basic",
    advanced_designer: "Advanced",
    saved_designs: "Saved Designs",
    design_name: "Design Name",
    design_name: "New Design",
    resize: "Resize here",
    fresh_cards: "Not used cards",
    is_mac: "Allow Only Mac Address",
  },
  cards: {
    card: "Card",
    auto_update: "Auto Update",
    username: "Username",
    password: "Password",
    used: "Used",
    profile: "Profile",
    batch: "Batch",
    expires_at: "Expires At",
    used_quota: "Used Quota",
    status: "Status",
    connection: "Connection",
    details: "Card Details",
  },
  admins: {
    auto_update: "Auto Update",
    add_admins: "Add Admins",
    edit_admins: "Edit Admin",
    first_name: "First Name",
    last_name: "Last Name",
    email: "Username",
    password: "Password",
    phone: "Phone",
    privileges: "Privileges",
    allowed_nases: "Allowed Nases",
    allowed_profiles: "Allowed Profiles",
    change: "Change",
    cancel: "Cancel",
    save: "Save",
    add: "Add",
    enable_all: "Enable All",
    disable_all: "Disable All",
    add_resellers: "Add Resellers",
    money: "Credit",
    prefix: "Prefix",
    type: "Type",
    custom_network_logo: "Custom Network Logo",
    custom_network_name: "Custom Network Name",
    signature: "Signature",
  },
  nases: {
    auto_update: "Auto Update",
    add_device: "Add Device",
    edit_device: "Edit Device",
    name: "Name",
    ip: "IP",
    secret: "Secret",
    username: "Username",
    password: "Password",
    api_port: "API Port",
    api_username: "API Username",
    api_password: "API Password",
    snmp_community: "SNMP Community",
    radius_secret: "Radius Secret",
    community: "Community",
    allowed_profiles: "Allowed Profiles",
    allowed_admins: "Allowed Admins",
    connection: "Connection",
    login: "Login credentials",
    other: "Addtional info",
    privileges: "Privileges",
    version: "Version",
    status: "Status",
    api: "API",
    cancel: "Cancel",
    save: "Save",
    add: "Add",
    settings: "Settings",
    online_sessions: "Online Sessions",
  },
  settings: {
    change_settings: "Change Settings",
    change_logo: "Change Logo",
    network_name: "Network Name",
    time_zone: "Time Zone",
    currency: "Currency",
    default_language: "Default Language",
    cancel: "Cancel",
    save: "Save",
    add: "Add",
    apply: "Apply",
    applied: "Applied",
    auto_backup: "Auto Backup",
    default_expiration_hour: "Accounts Default Expiration Time",
    system: "System Settings",
    ssra: "Mobile Application Settings",
    ssr_account_password_change: "Accounts can change password",
    ssr_account_profile_update:
      "Accounts can update profile for eligible profiles and pay using credits",
    reset: {
      reset_db_info: "Reset Database Data to factory defaults",
      reset_db_confirm:
        "Are you sure you want to reset Database? all data will be lost including default username & password",
      resetting_db: "Resetting database...",
      reset: "Reset",
    },
    phone_number: "Phone Number",
    status_url: "Status Url",
    nas: "Mikrotik Settings",
    nas_interim_update: "Mikrotik time to update values",
    resellers: "Resellers Settings",
    benefit_rate: "Benefit Rate %",
  },
  mikrotik: {
    services: "Mikrotik Services",
    auto_reboot: "Reboot device at 05:00am",
    auto_reboot_false: "Device auto reboot disabeled",
    whatsapp_messages_only: "Free use whatsapp Messages",
    whatsapp_messages_only_false: "Free use whatsapp Messages disabled",
    whatsapp_full: "Free use all whatsapp services",
    whatsapp_full_false: "Free use all whatsapp service disabled",
    dns: "Protection against viruses & porn sites DNS",
    dns_false: "Protection against viruses & porn sites DNS disabled",
    open_speed: "Open profile speeds at specific time",
    open_speed_false: "Open profile speeds at specific time",
  },
  support_tickets: {
    id: "Ticket ID",
    username: "Username",
    issue: "Issue",
    phone: "Phone",
    address: "Address",

    status: "Status",
    notes: "Additional Notes",
    ssr_notes: "SSR Comment",
    add_ticket: "Add Ticket",
    new: "New",
    in_progress: "In Progress",
    resolved: "Resolved",
    failed: "Failed",
    priority: {
      priority: "Priority",
      low: "Low",
      normal: "Normal",
      high: "High",
    },
    today: "Today",
  },
  notifications: {
    title: "Title",
    body: "Notification Text",
    receiver: "Receiver",
    sender: "Sender",
    image: "Image",
    status: "Status",
    created_at: "Issue Date",
    new_notification: "New Notification",
    show_archived: "View Archived",
    archived: "Archived",
    new: "New",
    error: "Cannot leave field empty",
    push: "Push Notification",
    confirm:
      "Are you sure want to send this notification to the selected destination?",
    type: {
      type: "Type",
      account_expiring: "Auto Account Expiring",
      account_expired: "Auto Account Expired",
      account_renew: "Auto Account Renew",
      account: "To Account",
      profile: "To Profile",
      nas: "To Network",
      ssr: "To All",
      global: "From System",
      support_new: "New Support Ticket",
      support_in_progress: "Support in progress",
      support_resolved: "Support resolved",
      support_failed: "Support failed",
      whatsapp: "Whatsapp messages",
    },
    wa: {
      status: "Connection State",
      statuses: {
        authenticated: "Authenticated",
        qr: "Scan Qr Code to connect",
        initialize: "Initializing Connection...",
        session_not_connected: "Waiting Connection...",
        session_connected: "Connected",
        session_not_found: "Whatsapp connection error",
        no_instance: "Waiting Subscription Key",
        free: "Connected to free service",
      },
      subscription_key: "Subscription Key",
      validate: "Validate Key",
      scan_qr: "Scan QR Code using whatsapp app link device feature",
      refresh: "Refresh",
    },
    config: {
      expiring_enabled: "Enable about to expire accounts notifications",
      expired_enabled: "Enable expired accounts notifications",
      renew_enabled: "Enable accounts profile renew notifications",
      expiring_body: "Abount to expire notification",
      expired_body: "Expired notificaion",
      renew_body: "Profile renew notification",
    },
  },
  admins_log: {
    id: "ID",
    user: "Username",
    operation: "Operation",
    details: "Details",
    created_at: "Date",
  },
  transactions: {
    id: "ID",
    username: "Operator",
    account_username: "Account Username",
    type: "Type",
    amount: "Amount",
    subject: "Subject",
    notes: "Notes",
    withdraw: "Withdraw",
    deposit: "Deposit",
    required_amount: "Required Amount",
    paid_amount: "Paid Amount",
    remaining: "has to pay",
    pay: "Pay",
    discount: "Discount",
    admin: "Reseller",
  },
  bills: {
    slug: "Code",
    price: "Price",
    paid: "Paid",
    from: "From",
    remaining: "Remaining",
    to: "To",
    notes: "Notes",
    pay: "Pay invoice",
    pay_confirm: "Are you sure you want to pay invoice with selected amount ?",
    total: {
      total_grand: "Grand Total",
      total_paid: "Total Paid",
      total_debt: "Total Debt",
      current_grand: "Currnent Page Total",
      current_paid: "Current Page Paid",
      current_debt: "Current Page debt",
    },
    invoice: {
      bill_to: "Bill To",
      issued_date: "Issued Date",
      account_id: "Account ID",
      bill_id: "Bill ID",
      profile: "Profile Name",
      description: "Description",
      amount: "Amount",
      remaining: "Remaining",
      issued_by: "Issued By",
    },
    invoice_number: "Invoice Number",
    admin: "Admin",
    invoice_at: "Invoice Date:",
    created_at: "Pay Date",
  },
  license: {
    license: "License",
    realm: "Realm",
    online: "Online Sessions",
    remaining: "Remaining Sessions",
    serial_number: "Serial Number",
    id: "Device ID",
    ip: "IP",
    device: "Device",
    subscription: "Subscription",
    full_name: "Full Name",
    name: "Name",
    network: "Network",
    expires_at: "Expires At",
    package: "Plan",
    package_name: "Name",
    package_details: "Details",
    package_months: "Duration (Months)",
    package_online_count: "Allowed online sessions",
    package_nases_count: "Allowed Nases",
    package_admins_count: "Allowed Admins",
    package_resellers_count: "Allowed Resellers",
    update: "Update License",
    update_error: "Updating License failed",
    update_success: "License updated successfully",
    phone: "Phone Number",
    email: "Email",
  },
  restore: {
    import_dma: "Import File From DMA",
    import_local: "Import File From SSR",
    choose_file: "Choose File",
    import: "Import",
    file_ready: "File Ready",
    restore_radacct: "Restore Sessions Data (Radacct)",
    restore_radacct_false: "Don't Restore Sessions Data (Radacct)",
    warning_radacct:
      "Warning ! this may take up to 30 minutes depending on database size",
    clean_restore: "Clear previous database",
    clean_restore_false: "Keep previous database",
    import_nas: "Import data extracted from SSR Nas",
    warning_nas:
      "Warning! Restoring nas data will erase all data in ssradius database",
  },
  backup: {
    file_name: "Name",
    action: "Action",
    download: "Download",
    delete: "Delete",
    now: "Backup Now",
  },
  iptv: {
    logo: "Channel Logo",
    name: "Channel Name",
    url: "Channel stream url",
    add: "Add Channel",
  },
  updates: {
    name: "Update Name:",
    change_log: "Change Log:",
    download_install: "Download & Install",
    downloading_message: "Downloading update...",
    downloading_fail: "Downloading Failed!",
    installing_message: "Installing update...",
    installing_fail: "Installing Failed!",
  },
  reports: {
    yearly: "Yearly",
    monthly: "Monthly",
    daily: "Daily",
    duration: "Fixed Duration",
    year: "Year",
    month: "Month",
    day: "Day",
    hour: "Hour",
    from: "From",
    to: "To",
    generate: "Generate",
    finance: "Financial Report",
    finance_report: "Financial Report",
    sells: "Sells Report",
    sells_report: "Sells Report",
    amount: "Amount",
    accounts: "Accounts",
    cards: "Cards",
    profile: "Profile",
    accounts_count: "Accounts Count",
    batch: "Batch",
    cards_count: "Cards Count",
    total: "Total",
  },
  bandwidth: {
    title: "Control Bandwidth Usage",
    total_use: "All Profiles",
    total_peak: "Peak Bandwidth Usage of all online accounts",
    total_average: "Average Bandwidth Usage of all online accounts",
    reset: "Reset",
    update: "Update",
    online_accounts: "Online Accounts",
    online_peak_band: "Peak Bandwidth of online accounts",
  },
  actions: {
    view: "View",
    edit: "Edit",
    renew: "Renew/Change Profile",
    clear: "Clear Sessions",
    disconnect: "Disconnect",
    enable: "Enable",
    disable: "Disable",
    delete: "Delete",
    view_profile: "View Profile",
    print: "Print",
    archive: "Archive",
    export_csv: "Export CSV",
    notify: "App Notify",
    view_sessions: "View Sessions",
    edit_popup: "Edit in new window",
    view_bills: "View invoices",
    renew_48: "Add 2 days wait",
    renew_48_free: "Activiate for 2 days free",
    edit_money: "Edit Credits",
    export_nas: "Export Nas Data",
    temp_speed: "Temporary Speed",
  },
  control: {
    rows_10: "10 Rows",
    rows_50: "50 Rows",
    rows_100: "100 Rows",
    rows_500: "500 Rows",
    rows_all: "All Rows",
    yes: "Yes",
    no: "No",
    back: "back",
    of: "of",
    columns: "Columns Visible",
    filter: "Filter",
    enabled: "Enabled",
    any: "any",
    hotspot: "Hotspot",
    ppp: "PPP",
    subscribtion: "Subscribtions",
    prepaid: "Cards",
    online: "Online",
    offline: "Offline",
    all: "All",
    active: "Active",
    expired: "Expired",
    seconds: "Seconds",
    minutes: "Minutes",
    hours: "Hours",
    unlimited: "Unlimited",
    details: "Details",
    created_at: "Created At",
    updated_at: "Last Edit",
    allowed_quota: "Allowed Quota",
    remaining_quota: "Remaining Quota",
    delete: "Delete",
    cancel: "Cancel",
    total: "Total Count:",
    from: "From",
    to: "To",
    disabled: "Disabled",
    auto: "Auto",
    device: "Device",
    cost: (ctx) => `This operation will cost you: ${ctx.named("cost")}`,
    money_left: (ctx) =>
      `Credit left ${ctx.named("original")} => ${ctx.named("left")}`,
    profile_cost: (ctx) => `Profile Price: ${ctx.named("price")}`,
    benefit_cost: (ctx) => `Benefit: ${ctx.named("price")}`,
    expiring: "Expiring Soon",
    hours_24: "24 Hours",
    months_1: "1 Month",
    months_6: "6 Months",
    years_1: "1 Year",
    fetch_failed: "failed",
    active_sessions: "Connected Devices Count",
    commit: "Commit Settings",
    commited: "Commited",
    warning: "Warning",
    save_filters: "Save Filters",
    sum: "Total",
    clear: "Clear",
    save: "Save",
    new_updates: "New Updates",
    click_to_update: "Click to update",
    default: "Default",
    ok: "Ok",
    benefit: "Reseller Benefit",
  },
  operations: {
    account_create_cost: "Account create with cost",
    account_create: "Account create",
    account_expiration: "Account expiration update",
    account_password: "Account password update",
    account_sessions: "Account clear sessions",
    account_username: "Account update username",
    account_allowed_sessions: "Account update allowed sessions",
    account_update: "Account update",
    account_delete: "Account delete",
    account_disconnect: "Account disconnect",
    account_enable: "Account enable",
    account_disable: "Account disable",
    account_renew: "Account renew",
    account_renew_cost: "Account renew with cost",
    account_renew48: "Account renew 48 hours (temp)",
    account_renew48_free: "Account renew 48 hours",
    admins_add: "Admin create",
    admins_update: "Admin update",
    admins_delete: "Admin delete",
    admins_password: "Admin password update",
    resellers_add: "Reseller create",
    resellers_money: "Reseller money update",
    card_design_save: "Card design save",
    cards_generate: "Card Batch generate",
    cards_generate_cost: "Card Batch generate with cost",
    card_password: "Card password update",
    card_expiration: "Card expiration update",
    card_allowed_sessions: "Card allowed sessions update",
    card_edit: "Card update",
    card_delete: "Card delete",
    cards_delete: "Card Batch delete",
    card_clear_sessions: "Card sessions clear",
    card_disconnect: "Card disconnect",
    card_enable: "Card enable",
    card_disable: "Card disable",
    mikrotik_services_update: "Mikrotik services update",
    nas_create: "Nas create",
    nas_update: "Nas update",
    nas_delete: "Nas delete",
    nas_api_commit: "Nas SSR settings commit",
    notify_account: "Notification sent to account",
    notify_card: "Notification sent to card",
    notify_profile: "Notification sent to profile accounts",
    notify_nas: "Notification sent to nas accounts",
    notify_ssr: "Notification sent to admin accounts",
    notifications_archive: "Notification archived",
    profiles_create: "Profile create",
    profile_update: "Profile update",
    profile_delete: "Profile delete",
    profile_disable: "Profile disable",
    profile_enabled: "Profile enable",
    profiles_delete: "Profile delete",
    session_disconnect: "Session disconnect",
    settings_update: "System Settings update",
    database_backup: "Database backup",
    support_ticket_delete: "Support Ticket delete",
    support_ticket_update: "Support Ticket update",
    support_ticket_create: "Support Ticket create",
    database_backup_auto: "Database auto backup",
    admin_login: "Admin Login",
    reseller_login: "Reseller Login",
    reseller_benefit: "Reseller Benefit",
    reseller_money_withdraw: "Reseller Money Withdraw",
    reseller_money_deposit: "Reseller Money Deposit",
    reseller_bill_delete: "Reseller Bill Delete",
    reseller_benefit_delete: "Reseller Deposit Benefit",
    bill_pay: "Invoice pay",
    bill_delete: "Invoice Delete",
  },
  currencies: {
    usd: "American Dollar $",
    tr: "Turkish Lira ₺",
    syp: "Syrian Lira SYP",
    iqd: "Iraqi Dinar IQD",
    jd: "Jordanian Dinar JD",
    lbp: "Lebanese Pound LBP",
    egp: "Egyptian Pound EGP",
    sar: "Saudi Riyal SAR",
  },
  errors: {
    accounts: {
      username_empty: "Username cannot be empty",
      username_2: "Username must be more than 2 letters",
      username_symbol: "Username cannot have * or @ signs",
      username_taken: "Username already taken",
      invalid_ip: "Invalid ip address",
      password_empty: "Password cannot be empty",
      password_2: "Password must be more than 2 letters",
      select_profile: "Please Select Profile",
      sessions_warning:
        "Warning: Disabling session recording causes the loss of used quota",
      serial_number_taken: "Serial Number already taken",
    },
    batches: {
      name_empty: "Please enter batch name",
      name_taken: "Batch name already taken",
      username_length_6: "Username must have 6 or more characters",
      password_length_3: "Password must have 3 or more characters",
      chars:
        "Username characters count is not enough to generate this cards amount",
      truncate_failed: "Removing failed!",
      not_enough:
        "Cannot create the required quantity with 3 characters, please delete old/unused cards or increase username characters count",
    },
    profiles: {
      name_empty: "Name cannot be empty",
      name_taken: "Profile name already taken",
      nases_1: "Please select at least 1 nas",
      speed_0: "Speed must be bigger than 0",
    },
    admins: {
      first_name: "First name is required",
      last_name: "Last name is required",
      username_3: "Username must have at least 3 characters",
      username_taken: "Username already taken",
      password_3: "Password must have at least 3 characters",
    },
    restore: {
      uploading: "Uploading failed !!",
      parsing: "Parsing failed !!",
      cleaning: "Cleaning failed !!",
      server: "Server Error !! please try again",
    },
    notifications: {
      empty: "Notification cannot be empty",
      wa: {
        not_found: "Invalid Key",
        already_used: "Key is already used",
        url: "Cannot contain: dot/http",
      },
    },
    support_tickets: {
      account_id: "Please select account",
      issue: "issue cannot by empty",
    },
    license: {
      expiring: (ctx) =>
        `WARNING: Your SSR realm subscription expires in ${ctx.named(
          "days"
        )} days renew in order not to lose control over your radius`,
      expiring_sessions: (ctx) =>
        `WARNING: You used ${ctx.named(
          "percent"
        )}% of your subscription online sessions consider upgrading your subscription otherwise new sessions will be not able to login`,
      expired:
        "EXPIRED: Your realm has expired please contact us to renew your subscription",
      expired_sessions:
        "EXPIRED: Your subscription online session is expiring users will have troubles logging in",
      auth_offline:
        "License verfication failed! check your internet connection, some features will not work until verify is done",
      privileges: "Insufficient Privileges!",
      license_nases_exceeded: "License nases count exceeded",
      license_admins_exceeded: "License admins count exceeded",
      license_resellers_exceeded: "License resellers count exceeded",
    },
    iptv: {
      name_empty: "Channel Name cannot be empty",
      url_empty: "Channel URL cannot be empty",
    },
  },
  messages: {
    delete_confirm: "Are you sure you want to delete ?",
    accounts: {
      delete_all:
        "Are you sure you want to delete selected accounts ? you can not undo this delete",
      clear_all:
        "Are you sure you want to clear sessions for selected accounts ? you can not undo this delete",
      disable: "Are you sure you want to disable selected Account ?",
      disconnect: "Are you sure you want to disconnect selected Account ?",
      clear: "Are you sure you want to reset selected account quota ?",
      enable: "Are you sure you want to enable selected Account ?",
      auto_mac:
        "Leave this field empty and SSRadius will fix first login MAC address",
      not_expiring_soon: "This account expires after long time",
      warning: "Warning",
      temp_renew:
        "Are you sure you want to give selected account 2 days wait duration to pay the bill ?",
      temp_renew_free:
        "Are you sure you want to give selected account 2 days free ?",
    },
    cards: {
      delete_all:
        "Are you sure you want to delete selected cards ? you can not undo this delete",
      disable: "Are you sure you want to disable selected card ?",
      disconnect: "Are you sure you want to disconnect selected card ?",
      clear: "Are you sure you want to reset selected card quota ?",
      enable: "Are you sure you want to enable selected card ?",
      truncate: "Are you sure you want to remove all expired cards ?",
    },
    batches: {
      is_mac: "Allow only first login MAC Address to use card",
    },
    profiles: {
      delete_all:
        "Are you sure you want to delete selected profiles and all associated accounts and cards ? you can not undo this delete",
      disable_all:
        "Are you sure you want to disable selected profiles ? you only can not add new account with disabled profile, previous accounts with the selected profile will still work",
      open:
        "Open Profile Speed will not work if you use shared profile option or custom attributes",
    },
    admins: {
      delete_all:
        "Are you sure you want to delete selected admins ? you can not undo this delete",
    },
    restore: {
      uploading: "10% uploading data",
      parsing: "40% parsing data",
      cleaning: "90% cleaning cache",
    },
    support_tickets: {
      delete_all:
        "Are you sure you want to delete selected support tickets ? you can not undo this delete",
    },
    notifications: {
      archive: "Are you sure to archive and hide selected notification?",
      archiveAll: "Are you sure to archive and hide selected notifications?",
      wa_delete:
        "Are you sure you want to delete Whatsapp Subscription License?",
    },
    nases: {
      delete_all:
        "Are you sure you want to delete selected nases ? you can not undo this delete",
    },
    transactions: {
      no_debts: "Congrats your network is debt free.",
    },
    bills: {
      delete:
        "Are you sure you want to delete selected bill ? you can not undo this delete.",
    },
  },
};
