<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      v-if="this.$store.getters.settings.license.active"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div
        @click.prevent="hide"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        class="inline-block align-bottom bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all my-24 max-w-5xl w-full"
      >
        <div class="flex flex-col">
          <div class="bg-ssr-side_bar-background flex justify-between">
            <div class="md:text-2xl text-white font-bold py-2 pl-6">
              {{ $t("accounts.traffic") }}
            </div>

            <div
              @click.prevent="hide"
              class="rounded-full cursor-pointer bg-ssr-side_bar-button_hover text-white text-xs my-2 mr-6 w-28 flex justify-center items-center font-bold"
            >
              {{ $t("accounts.cancel") }}
            </div>
          </div>
          <div class="flex flex-wrap justify-center mt-4 px-10 gap-10 mb-4">
            <div v-for="(chart, index) in charts" :key="index">
              <Line
                v-if="!chart.offline"
                style="height: 300px; width: 600px; position: relative;"
                id="my-chart-id"
                :chart-options="chart.chartOptions"
                :chart-data="chart.chartData"
              />
              <div
                class="flex justify-center items-center bg-gray-200 text-2xl"
                style="height: 300px; width: 600px;"
                v-else
              >
                <p>{{ chart.offline }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { hasPrivilege } from "../../global_constants.js";
import { mapGetters } from "vuex";
import axios from "axios";
import ExpiredWindow from "../ExpiredWindow.vue";
// import Chart from "chart.js/auto";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
export default {
  name: "LiveTraffic",
  props: ["visible", "selectedAccount", "compacted"],
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  components: { Line },
  data() {
    return {
      charts: [],
      data: null,
      timer: null,
    };
  },
  async created() {
    await this.createCharts();
    var result = await this.fetchData();
    this.timer = setInterval(() => {
      this.fetchData();
    }, 5000);
  },
  unmounted() {
    if (this.timer != null) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    async createCharts() {
      this.selectedAccount.active_sessions.forEach((session) => {
        this.charts.push({
          chartData: {
            labels: [],
            datasets: [
              {
                data: [],
                label: "Download",
                backgroundColor: "rgba(99, 99, 255,0.6)",
                borderColor: "rgb(99, 99, 255)",
                fill: true,
                borderRadius: Number.MAX_VALUE,
                borderWidth: 0,
                barPercentage: 0.6,
              },
              {
                data: [],
                label: "Upload",
                backgroundColor: "rgba(255, 99, 99,0.6)",
                borderColor: "rgb(255, 99, 99)",
                fill: true,
                borderRadius: Number.MAX_VALUE,
                borderWidth: 0,
                barPercentage: 0.6,
              },
            ],
          },
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
              mode: "index",
              intersect: false,
            },
            stacked: false,
            plugins: {
              legend: {
                display: true,
                position: "bottom",
              },
              title: {
                display: true,
                text:
                  session.callingstationid + " / " + session.framedipaddress,
              },
              tooltip: {
                enabled: true,
                position: "nearest",
                callbacks: {
                  label: function(context) {
                    let label = parseFloat(context.parsed.y / 1000).toFixed(2);
                    label += " mbps";
                    return label;
                  },
                },
              },
            },
            scales: {
              xAxis: {
                grid: {
                  display: true,
                },
                ticks: {
                  display: false,
                  color: "#black",
                  backgroundColor: "#black",
                  font: {
                    size: 14,
                    weight: "bold",
                  },
                },
              },
              yAxis: {
                max: this.selectedAccount.profile.download_rate_kb,
                min: 0,
                title: "login",
                grid: {
                  display: false,
                },
                ticks: {
                  stepSize: 1024,
                  color: "black",
                  backgroundColor: "black",
                  font: {
                    size: 14,
                    weight: "bold",
                  },
                  callback: function(label, index, labels) {
                    return parseFloat(label / 1024).toFixed(0) + "m";
                  },
                },
                scaleLabel: {
                  display: true,
                  labelString: "1m = 1024",
                },
              },
            },
          },
        });
      });
    },
    hide() {
      this.$emit("hide");
    },
    new_data(session, tx = 1, rx = 1, date = new Date().toDateString()) {
      session.chartData.labels = [
        ...session.chartData.labels,
        this.formatDate(date),
      ];
      session.chartData.datasets[0].data = [
        ...session.chartData.datasets[0].data,
        tx,
      ];
      session.chartData.datasets[1].data = [
        ...session.chartData.datasets[1].data,
        rx,
      ];
    },
    async fetchData() {
      this.selectedAccount.active_sessions.forEach(async (session, index) => {
        var id = this.selectedAccount.active_sessions[index].radacctid;
        const response = await axios.get(
          `sessions/traffic/${id}?index=${index}`
        );
        if (response.status == 200) {
          const result = response.data.data;
          this.new_data(this.charts[index], result.tx, result.rx, result.date);
          return true;
        } else if (response.status == 210) {
          this.charts[index].offline = "OFFLINE";
        }
        // if (
        //   this.selectedAccount.active_sessions[index].framedprotocol == "PPP"
        // ) {
        // } else {
        //   this.charts[index].offline = "HOTSPOT coming soon...";
        // }
      });
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("yyyy-MM-DD, h:mm:ss a");
    },
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
  },
};
</script>
