<template>
  <div class="main-content flex-1 relative bg-gray-100">
    <loading-overlay :visible="loading" />
    <WarnningConfirm
      :visible="showOpenDialog"
      :message="$t('messages.profiles.open')"
      @confirmed="
        profile.has_open_speed = true;
        showOpenDialog = false;
      "
      @hide="
        profile.has_open_speed = false;
        showOpenDialog = false;
      "
    />
    <div
      v-if="this.$store.getters.settings.license.active"
      class="flex justify-center w-full"
    >
      <div class="bg-gray-300 rounded-xl w-full m-6 flex flex-col pb-12 px-20">
        <div class="flex flex-col justify-center items-center">
          <div class="grid grid-cols-2 gap-6 mt-6 lg:w-1/2">
            <div>
              <label class="font-bold">{{ $t("profiles.name") }}</label>
              <input
                v-model="profile.name"
                @input="validate"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :class="{ 'shadow-error': errors.name }"
              />
            </div>
            <div>
              <label class="text-gray-300">.</label>
              <toggle-button
                :value="profile.enabled"
                :enabled_name="$t('profiles.enabled')"
                :disabled_name="$t('profiles.disabled')"
                @toggle="profile.enabled = !profile.enabled"
              />
            </div>
            <div class="col-span-2">
              <label class="font-bold mt-6">{{ $t("profiles.service") }}</label>
              <select
                v-model="profile.service"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              >
                <option
                  v-for="service in [
                    { name: $t('control.any'), id: 'any' },
                    { name: $t('control.hotspot'), id: 'hostspot' },
                    { name: $t('control.ppp'), id: 'ppp' },
                  ]"
                  v-bind:key="service.id"
                  :value="service.id"
                >
                  {{ service.name }}
                </option>
              </select>
            </div>
            <div>
              <label class="font-bold">{{ $t("profiles.price") }}</label>
              <div class="bg-white rounded-full w-full inline-flex shadow-lg">
                <div
                  class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
                >
                  <button @click.prevent="profile.price = profile.price + 1">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      profile.price =
                        profile.price > 0
                          ? profile.price - 1
                          : (profile.price = 0)
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  v-model="profile.price"
                  class="rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>

            <div>
              <label class="font-bold mt-6">{{ $t("profiles.type") }}</label>
              <select
                v-model="profile.type"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              >
                <option
                  v-for="type in [
                    { name: $t('control.subscribtion'), id: 'subscribtion' },
                    { name: $t('control.prepaid'), id: 'prepaid' },
                  ]"
                  v-bind:key="type.id"
                  :value="type.id"
                >
                  {{ type.name }}
                </option>
              </select>
            </div>
            <div class="col-span-2">
              <toggle-button
                :value="unlimited_speed"
                :enabled_name="$t('profiles.unlimited_speed')"
                :disabled_name="$t('profiles.unlimited_speed_false')"
                @toggle="
                  unlimited_speed = !unlimited_speed;
                  profile.download_rate_kb = 0;
                  profile.upload_rate_kb = 0;
                  validate();
                "
              />
            </div>
            <!-- <div>
              <div class="font-bold">{{ $t("profiles.price") }}</div>
              <numerical-spinner
                :value="profile.price"
                @increase="profile.price = profile.price + 1"
                @decrease="
                  profile.price =
                    profile.price > 0 ? profile.price - 1 : (profile.price = 0)
                "
              />
            </div>
            <div></div> -->
            <!-- <div class="col-span-2">
              <label class="text-2xl mt-6 mb-3">{{
                $t("profiles.connection_speed")
              }}</label>
            </div> -->
            <div v-if="!unlimited_speed">
              <label class="font-bold">{{
                $t("profiles.download_speed")
              }}</label>
              <div
                class="bg-white rounded-full w-full inline-flex shadow-lg"
                :class="{ 'shadow-error': errors.download }"
              >
                <div
                  class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
                >
                  <button
                    @click.prevent="
                      profile.download_rate_kb = profile.download_rate_kb + 1;
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      profile.download_rate_kb =
                        profile.download_rate_kb > 0
                          ? profile.download_rate_kb - 1
                          : (profile.download_rate_kb = 0);
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  @input="validate()"
                  v-model="profile.download_rate_kb"
                  class="rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>
            <div v-if="!unlimited_speed">
              <div class="text-gray-300">.</div>
              <select
                v-model="download_rate_limit_factor"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              >
                <option
                  v-for="unit in [
                    { name: 'Kb/s', id: 1 },
                    { name: 'Mb/s', id: 1024 },
                  ]"
                  v-bind:key="unit.id"
                  :value="unit.id"
                >
                  {{ unit.name }}
                </option>
              </select>
            </div>
            <div v-if="!unlimited_speed">
              <div class="font-bold">
                {{ $t("profiles.upload_speed") }}
              </div>
              <div
                class="bg-white rounded-full w-full inline-flex shadow-lg"
                :class="{ 'shadow-error': errors.upload }"
              >
                <div
                  class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
                >
                  <button
                    @click.prevent="
                      profile.upload_rate_kb = profile.upload_rate_kb + 1;
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      profile.upload_rate_kb =
                        profile.upload_rate_kb > 0
                          ? profile.upload_rate_kb - 1
                          : (profile.upload_rate_kb = 0);
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  @input="validate()"
                  v-model="profile.upload_rate_kb"
                  class="rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>
            <div v-if="!unlimited_speed">
              <div class="text-gray-300">.</div>
              <select
                v-model="upload_rate_limit_factor"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              >
                <option
                  v-for="unit in [
                    { name: 'Kb/s', id: 1 },
                    { name: 'Mb/s', id: 1024 },
                  ]"
                  v-bind:key="unit.id"
                  :value="unit.id"
                >
                  {{ unit.name }}
                </option>
              </select>
            </div>
            <div class="col-span-2">
              <toggle-button
                :value="profile.has_expiration"
                :enabled_name="$t('profiles.expiration_date')"
                :disabled_name="$t('profiles.expiration_date_false')"
                @toggle="profile.has_expiration = !profile.has_expiration"
              />
            </div>
            <div v-if="profile.has_expiration">
              <div class="bg-white rounded-full w-full inline-flex shadow-lg">
                <div
                  class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
                >
                  <button
                    @click.prevent="
                      profile.expiration_value = profile.expiration_value + 1
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      profile.expiration_value =
                        profile.expiration_value > 1
                          ? profile.expiration_value - 1
                          : (profile.expiration_value = 1)
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  v-model="profile.expiration_value"
                  class="rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>
            <div v-if="profile.has_expiration">
              <select
                v-model="profile.expiration_unit"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              >
                <option
                  v-for="unit in [
                    { name: $t('profiles.unit.months'), id: 'months' },
                    { name: $t('profiles.unit.days'), id: 'days' },
                    { name: $t('profiles.unit.hours'), id: 'hours' },
                  ]"
                  v-bind:key="unit.id"
                  :value="unit.id"
                >
                  {{ unit.name }}
                </option>
              </select>
            </div>
            <div class="col-span-2">
              <toggle-button
                :value="profile.traffic_limited"
                :enabled_name="$t('profiles.limited_quota')"
                :disabled_name="$t('profiles.limited_quota_false')"
                @toggle="profile.traffic_limited = !profile.traffic_limited"
              />
            </div>

            <div v-if="profile.traffic_limited">
              <div class="bg-white rounded-full w-full inline-flex shadow-lg">
                <div
                  class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
                >
                  <button
                    @click.prevent="
                      profile.traffic_limit_value_mb =
                        profile.traffic_limit_value_mb + 10
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      profile.traffic_limit_value_mb =
                        profile.traffic_limit_value_mb > 1
                          ? profile.traffic_limit_value_mb - 10
                          : (profile.traffic_limit_value_mb = 1)
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  v-model="profile.traffic_limit_value_mb"
                  class="rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>
            <div v-if="profile.traffic_limited">
              <select
                v-model="limit_factor"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              >
                <option
                  v-for="unit in [
                    { name: 'MB', id: '1' },
                    { name: 'GB', id: '1024' },
                  ]"
                  v-bind:key="unit.id"
                  :value="unit.id"
                >
                  {{ unit.name }}
                </option>
              </select>
            </div>
            <div class="col-span-2" v-if="settings.features.open_profile">
              <toggle-button
                :value="profile.has_open_speed"
                :enabled_name="$t('profiles.open_speed')"
                :disabled_name="$t('profiles.open_speed')"
                @toggle="toggleOpenSpeed()"
              />
            </div>
            <div v-if="profile.has_open_speed">
              <div class="font-bold">
                {{ $t("profiles.start_hour") }}
              </div>
              <select
                v-model="profile.open_start_hour"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              >
                <option
                  v-for="unit in [
                    { name: '00:00 AM', id: '0' },
                    { name: '01:00 AM', id: '1' },
                    { name: '02:00 AM', id: '2' },
                    { name: '03:00 AM', id: '3' },
                    { name: '04:00 AM', id: '4' },
                    { name: '05:00 AM', id: '5' },
                    { name: '06:00 AM', id: '6' },
                    { name: '07:00 AM', id: '7' },
                    { name: '08:00 AM', id: '8' },
                    { name: '09:00 AM', id: '9' },
                    { name: '10:00 AM', id: '10' },
                    { name: '11:00 AM', id: '11' },
                    { name: '12:00 PM', id: '12' },
                    { name: '13:00 PM', id: '13' },
                    { name: '14:00 PM', id: '14' },
                    { name: '15:00 PM', id: '15' },
                    { name: '16:00 PM', id: '16' },
                    { name: '17:00 PM', id: '17' },
                    { name: '18:00 PM', id: '18' },
                    { name: '19:00 PM', id: '19' },
                    { name: '20:00 PM', id: '20' },
                    { name: '21:00 PM', id: '21' },
                    { name: '22:00 PM', id: '22' },
                    { name: '23:00 PM', id: '23' },
                  ]"
                  v-bind:key="unit.id"
                  :value="unit.id"
                >
                  {{ unit.name }}
                </option>
              </select>
            </div>
            <div v-if="profile.has_open_speed">
              <div class="font-bold">
                {{ $t("profiles.stop_hour") }}
              </div>
              <select
                v-model="profile.open_stop_hour"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              >
                <option
                  v-for="unit in [
                    { name: '00:00 AM', id: '0' },
                    { name: '01:00 AM', id: '1' },
                    { name: '02:00 AM', id: '2' },
                    { name: '03:00 AM', id: '3' },
                    { name: '04:00 AM', id: '4' },
                    { name: '05:00 AM', id: '5' },
                    { name: '06:00 AM', id: '6' },
                    { name: '07:00 AM', id: '7' },
                    { name: '08:00 AM', id: '8' },
                    { name: '09:00 AM', id: '9' },
                    { name: '10:00 AM', id: '10' },
                    { name: '11:00 AM', id: '11' },
                    { name: '12:00 PM', id: '12' },
                    { name: '13:00 PM', id: '13' },
                    { name: '14:00 PM', id: '14' },
                    { name: '15:00 PM', id: '15' },
                    { name: '16:00 PM', id: '16' },
                    { name: '17:00 PM', id: '17' },
                    { name: '18:00 PM', id: '18' },
                    { name: '19:00 PM', id: '19' },
                    { name: '20:00 PM', id: '20' },
                    { name: '21:00 PM', id: '21' },
                    { name: '22:00 PM', id: '22' },
                    { name: '23:00 PM', id: '23' },
                  ]"
                  v-bind:key="unit.id"
                  :value="unit.id"
                >
                  {{ unit.name }}
                </option>
              </select>
            </div>
            <div v-if="profile.has_open_speed">
              <div class="font-bold">
                {{ $t("profiles.open_download") }}
              </div>
              <div class="bg-white rounded-full w-full inline-flex shadow-lg">
                <div
                  class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
                >
                  <button
                    @click.prevent="
                      profile.open_download_rate_mb =
                        profile.open_download_rate_mb + 1
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      profile.open_download_rate_mb =
                        profile.open_download_rate_mb > 1
                          ? profile.open_download_rate_mb - 1
                          : (profile.open_download_rate_mb = 1)
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  v-model="profile.open_download_rate_mb"
                  class="rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>
            <div v-if="profile.has_open_speed">
              <div class="font-bold">
                {{ $t("profiles.open_upload") }}
              </div>
              <div class="bg-white rounded-full w-full inline-flex shadow-lg">
                <div
                  class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
                >
                  <button
                    @click.prevent="
                      profile.open_upload_rate_mb =
                        profile.open_upload_rate_mb + 1
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      profile.open_upload_rate_mb =
                        profile.open_upload_rate_mb > 1
                          ? profile.open_upload_rate_mb - 1
                          : (profile.open_upload_rate_mb = 1)
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  v-model="profile.open_upload_rate_mb"
                  class="rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>

            <div>
              <toggle-button
                :value="profile.shared"
                :enabled_name="$t('profiles.shared')"
                :disabled_name="$t('profiles.shared_false')"
                @toggle="profile.shared = !profile.shared"
              />
            </div>
            <div class="text-sm font-bold">
              {{ $t("profiles.shared_note") }}
            </div>

            <!-- <div v-if="profile.shared">
              <label class="font-bold">{{
                $t("profiles.shared_download_speed")
              }}</label>
              <div
                class="bg-white rounded-full w-full inline-flex shadow-lg"
                :class="{ 'shadow-error': errors.shared_download }"
              >
                <div
                  class="
                    bg-gray-800
                    flex
                    items-center
                    justify-center
                    text-indigo-400
                    rounded-full
                    px-1
                    m-0
                  "
                >
                  <button
                    @click.prevent="
                      profile.shared_download_rate_kb = profile.shared_download_rate_kb + 1;
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      profile.shared_download_rate_kb =
                        profile.shared_download_rate_kb > 0
                          ? profile.shared_download_rate_kb - 1
                          : (profile.shared_download_rate_kb = 0);
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  @input="validate()"
                  v-model="profile.shared_download_rate_kb"
                  class="rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>
            <div v-if="profile.shared">
              <div class="text-gray-300">.</div>
              <select
              disabled
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                  opacity-50
                "
              >
                <option
                  v-for="unit in [
                    { name: 'Kb/s', id: 1 },
                    { name: 'Mb/s', id: 1024 },
                  ]"
                  v-bind:key="unit.id"
                  :value="unit.id"
                >
                  {{ unit.name }}
                </option>
              </select>
            </div>
            <div v-if="profile.shared">
              <div class="font-bold">
                {{ $t("profiles.shared_upload_speed") }}
              </div>
              <div
                class="bg-white rounded-full w-full inline-flex shadow-lg"
                :class="{ 'shadow-error': errors.shared_upload }"
              >
                <div
                  class="
                    bg-gray-800
                    flex
                    items-center
                    justify-center
                    text-indigo-400
                    rounded-full
                    px-1
                    m-0
                  "
                >
                  <button
                    @click.prevent="
                      profile.shared_upload_rate_kb = profile.shared_upload_rate_kb + 1;
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      profile.shared_upload_rate_kb =
                        profile.shared_upload_rate_kb > 0
                          ? profile.shared_upload_rate_kb - 1
                          : (profile.shared_upload_rate_kb = 0);
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  @input="validate()"
                  v-model="profile.shared_upload_rate_kb"
                  class="rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>
            <div v-if="profile.shared">
              <div class="text-gray-300">.</div>
              <select
              disabled
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                  opacity-50
                "
              >
                <option
                  v-for="unit in [
                    { name: 'Kb/s', id: 1 },
                    { name: 'Mb/s', id: 1024 },
                  ]"
                  v-bind:key="unit.id"
                  :value="unit.id"
                >
                  {{ unit.name }}
                </option>
              </select>
            </div> -->

            <div class="col-span-2" v-if="!ip_pool_enabled">
              <label class="font-bold mt-12">{{
                $t("profiles.allowed_nases")
              }}</label>
              <MultiSelect
                v-model="this.allowed_nases"
                :close-on-select="false"
                :searchable="true"
                @click="validate()"
                :class="{ 'shadow-error': errors.nas }"
                :mode="'tags'"
                :placeholder="'NONE'"
                :options="nases"
                :classes="{
                  container:
                    'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white rounded-3xl text-base leading-snug outline-none',
                  tag:
                    'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded rounded-full mr-1 mb-1 flex items-center whitespace-nowrap',
                }"
              />
            </div>
            <div class="col-span-2" v-if="ip_pool_enabled">
              <label class="font-bold mt-12">{{
                $t("profiles.allowed_nases")
              }}</label>
              <MultiSelect
                v-model="this.allowed_nases"
                :close-on-select="false"
                :searchable="true"
                @click="fetch_pools()"
                :class="{ 'shadow-error': errors.nas }"
                :mode="'single'"
                :placeholder="'NONE'"
                :options="nases"
                :classes="{
                  container:
                    'relative mx-auto w-full flex items-center justify-end py-1 box-border cursor-pointer border border-gray-300 rounded bg-white rounded-3xl text-base leading-snug outline-none',
                  tag:
                    'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded rounded-full mr-1 mb-1 flex items-center whitespace-nowrap',
                }"
              />
            </div>
            <div>
              <toggle-button
                :value="ip_pool_enabled"
                :enabled_name="$t('profiles.ip_pool')"
                :disabled_name="$t('profiles.ip_pool')"
                @toggle="
                  allowed_nases = [];
                  ip_pool_enabled = !ip_pool_enabled;
                "
              />
            </div>
            <div>
              <select
                v-if="ip_pool_enabled"
                name="ip_pool"
                id="ip_pool"
                @change="validate()"
                v-model="profile.ip_pool"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              >
                <option>{{ $t("profiles.select_nas") }}</option>
                <option
                  v-for="pool in pools"
                  v-bind:key="pool.id"
                  :value="pool.name"
                >
                  {{ pool.name }}
                </option>
              </select>
            </div>
            <div
              class="col-span-2"
              v-if="hasPrivilege('admins_assign_profiles')"
            >
              <label class="font-bold mt-12">{{
                $t("nases.allowed_admins")
              }}</label>
              <MultiSelect
                v-model="this.allowed_admins"
                :close-on-select="false"
                :searchable="true"
                @click="validate()"
                :class="{ 'shadow-error': errors.nas }"
                :mode="'tags'"
                :placeholder="'NONE'"
                :options="admins"
                :classes="{
                  container:
                    'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white rounded-3xl text-base leading-snug outline-none',
                  tag:
                    'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded rounded-full mr-1 mb-1 flex items-center whitespace-nowrap',
                }"
              />
            </div>
            <div class="col-span-2">
              <div class="font-bold">
                {{ $t("profiles.resellers_allowed_sessions") }}
              </div>
              <div
                class="bg-white rounded-full w-full inline-flex shadow-lg"
                :class="{ 'shadow-error': errors.resellers_allowed_sessions }"
              >
                <div
                  class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
                >
                  <button
                    @click.prevent="
                      profile.resellers_allowed_sessions =
                        profile.resellers_allowed_sessions + 1;
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      profile.resellers_allowed_sessions =
                        profile.resellers_allowed_sessions > 0
                          ? profile.resellers_allowed_sessions - 1
                          : (profile.resellers_allowed_sessions = 0);
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  @input="validate()"
                  v-model="profile.resellers_allowed_sessions"
                  class="rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>
            <div>
              <toggle-button
                :value="profile.app_allowed"
                :enabled_name="$t('profiles.app_allowed')"
                :disabled_name="$t('profiles.app_allowed_false')"
                @toggle="profile.app_allowed = !profile.app_allowed"
              />
            </div>
            <div class="text-sm font-bold">
              {{ $t("profiles.app_allowed_note") }}
            </div>
            <div class="col-span-2 border-b border-black w-full"></div>
            <div>
              <div class="font-bold">
                {{ $t("profiles.expiration_profile") }}
              </div>
              <toggle-button
                :value="profile.has_expiration_profile"
                :enabled_name="$t('control.yes')"
                :disabled_name="$t('control.no')"
                @toggle="
                  profile.has_expiration_profile = !profile.has_expiration_profile
                "
              />
            </div>

            <div class="text-sm font-bold">
              <div class="text-gray-300">.</div>
              <div>{{ $t("profiles.expiration_profile_note") }}</div>
            </div>
            <div v-if="profile.has_expiration_profile" class="col-span-2">
              <div>{{ $t("profiles.expiration_profile_message") }}</div>
              <textarea
                class="bg-white rounded-3xl w-full h-24 shadow-lg p-1 pl-3 focus:outline-none"
                @input="validate"
                v-model="profile.expiration_profile_message"
                cols="30"
                rows="10"
              ></textarea>
            </div>

            <!-- <div v-if="profile.has_expiration_profile">
              <div
                class="bg-white rounded-full w-full inline-flex shadow-lg"
                :class="{ 'shadow-error': errors.expiration_profile_speed }"
              >
                <div
                  class="
                    bg-gray-800
                    flex
                    items-center
                    justify-center
                    text-indigo-400
                    rounded-full
                    px-1
                    m-0
                  "
                >
                  <button
                    @click.prevent="
                      profile.expiration_profile_speed =
                        profile.expiration_profile_speed + 1;
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      profile.expiration_profile_speed =
                        profile.expiration_profile_speed > 0
                          ? profile.expiration_profile_speed - 1
                          : (profile.expiration_profile_speed = 0);
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  @input="validate()"
                  v-model="profile.expiration_profile_speed"
                  class="rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>
            <div v-if="profile.has_expiration_profile">
              <select
                v-model="expiration_rate_limit_factor"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                "
              >
                <option
                  v-for="unit in [
                    { name: 'Kb/s', id: 1 },
                    { name: 'Mb/s', id: 1024 },
                  ]"
                  v-bind:key="unit.id"
                  :value="unit.id"
                >
                  {{ unit.name }}
                </option>
              </select>
            </div> -->
            <div class="col-span-2 border-b border-black w-full"></div>
            <div class="col-span-2 h-32">
              <label class="font-bold">{{
                $t("profiles.custom_attributes")
              }}</label>
              <textarea
                class="bg-white rounded-3xl w-full h-24 shadow-lg p-1 pl-3 focus:outline-none"
                @input="validate"
                v-model="profile.custom_attributes"
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div class="col-span-2">
              <div
                class="text-gray-300 mt-0.5"
                :class="{ 'text-red-600': errors.name }"
              >
                *{{ errors.name }}
              </div>
              <div
                class="text-gray-300 mt-0.5"
                :class="{ 'text-red-600': errors.upload || errors.download }"
              >
                *{{ errors.upload || errors.download }}
              </div>
              <div
                class="text-gray-300 mt-0.5"
                :class="{ 'text-red-600': errors.expiration_profile_speed }"
              >
                *{{ errors.expiration_profile_speed }}
              </div>
              <div
                class="text-gray-300 mt-0.5"
                :class="{
                  'text-red-600':
                    errors.shared_upload || errors.shared_download,
                }"
              >
                *{{ errors.shared_upload || errors.shared_download }}
              </div>
              <div
                class="text-gray-300 mt-0.5"
                :class="{ 'text-red-600': errors.nas }"
              >
                *{{ errors.nas }}
              </div>
            </div>
            <div class="col-span-2">
              <button
                @click.prevent="store"
                class="w-full bg-green-500 text-white rounded-full py-1 px-3"
                :class="{
                  'hover:bg-white hover:text-green-500': validated,
                  'bg-opacity-50 cursor-not-allowed': !validated,
                }"
                :disabled="!validated"
              >
                {{ this.profile.id ? $t("profiles.save") : $t("profiles.add") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import NumericalSpinner from "../components/formElements/NumericalSpinner.vue";
import ToggleButton from "../components/formElements/ToggleButton.vue";
import Dropdown from "../components/formElements/Dropdown.vue";
import MultiSelect from "@vueform/multiselect";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import ErrorMessage from "../components/modals/ErrorMessage.vue";
import WarnningConfirm from "../components/modals/WarnningConfirm.vue";
import { hasPrivilege } from "../global_constants";
import ExpiredWindow from "../components/ExpiredWindow.vue";
export default {
  components: {
    NumericalSpinner,
    ToggleButton,
    Dropdown,
    MultiSelect,
    LoadingOverlay,
    ErrorMessage,
    ExpiredWindow,
    WarnningConfirm,
  },
  name: "AddProfile",
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  data() {
    return {
      error: "",
      message: "",
      has_static_ip: false,
      limit_factor: 1,
      download_rate_limit_factor: 1024,
      upload_rate_limit_factor: 1024,
      expiration_rate_limit_factor: 1024,
      has_expiration_proxy: false,
      loading: false,
      showOpenDialog: false,
      profile: {
        name: "",
        service: "any",
        type: "subscribtion",
        enabled: true,
        shared: false,
        price: 0,
        download_rate_kb: 0,
        upload_rate_kb: 0,
        has_expiration: true,
        fixed_expiration: "",
        expiration_unit: "months",
        expiration_value: 1,
        traffic_limited: false,
        traffic_limit_value_mb: 0,
        ip_pool: "",
        custom_attributes: "",
        resellers_allowed_sessions: 1,
        has_open_speed: false,
        open_start_hour: 2,
        open_stop_hour: 6,
        open_upload_rate_mb: 10,
        open_download_rate_mb: 10,
        shared_download_rate_kb: 512,
        shared_upload_rate_kb: 512,
        has_expiration_profile: false,
        expiration_profile_speed: 5,
        expiration_profile_message:
          "لقد انتهى اشتراكك في شبكتنا يرجى مراجعة مركز الشبكة لتفعيل الاشتراك",
        app_allowed: false,
      },
      unlimited_speed: false,
      ip_pool_enabled: false,
      pools: [],
      profiles: null,
      nases: [],
      allowed_nases: [],
      allowed_admins: [],
      admins: [],
      errors: {
        name: null,
        download: null,
        upload: null,
        nas: null,
        shared_download: null,
        shared_upload: null,
        expiration_profile_speed: null,
      },
      validated: false,
    };
  },
  async created() {
    this.loading = true;
    if (this.hasPrivilege("nases_view_index_nases")) {
      await this.fetchNases();
    }
    if (
      this.hasPrivilege("admins_view_index_admins") ||
      this.hasPrivilege("admins_view_search_admins")
    ) {
      await this.fetchAdmins();
    }

    if (this.$route.params.id) {
      await this.fetchProfile(this.$route.params.id);
      this.validated = true;
    } else {
      var temp = [];
      this.nases.forEach((element) => {
        temp.push(element.value);
      });
      this.allowed_nases = temp;
      var temp = [];
      this.admins.forEach((element) => {
        temp.push(element.value);
      });
      this.allowed_admins = temp;
    }
    if (this.settings.expiration_profile_message) {
      this.profile.expiration_profile_message = this.settings.expiration_profile_message;
    }
    this.loading = false;
  },
  // watch: {
  //   $route(to, from) {
  //     if (this.$route.params.id == null) {
  //       this.profile = {
  //         name: "",
  //         service: "any",
  //         type: "subscribtion",
  //         enabled: true,
  //         shared: false,
  //         price: 0,
  //         download_rate_kb: 0,
  //         upload_rate_kb: 0,
  //         has_expiration: false,
  //         fixed_expiration: "",
  //         expiration_unit: "months",
  //         expiration_value: 0,
  //         traffic_limited: false,
  //         traffic_limit_value_mb: 0,
  //         ip_pool: "",
  //         custom_attributes: "",
  //       };
  //     }
  //   },
  // },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async store() {
      this.loading = true;
      if (!this.ip_pool_enabled) this.profile.ip_pool = null;
      if (this.unlimited_speed) {
        this.profile.download_rate_kb = -1;
        this.profile.upload_rate_kb = -1;
      }
      if (this.profile.id) {
        const response = await axios.put(`/profiles/${this.profile.id}`, {
          name: this.profile.name,
          service: this.profile.service,
          type: this.profile.type,
          enabled: this.profile.enabled,
          price: this.profile.price,
          download_rate_kb:
            this.profile.download_rate_kb * this.download_rate_limit_factor,
          upload_rate_kb:
            this.profile.upload_rate_kb * this.upload_rate_limit_factor,
          has_expiration: this.profile.has_expiration,
          fixed_expiration: this.profile.fixed_expiration,
          expiration_unit: this.profile.expiration_unit,
          expiration_value: this.profile.expiration_value,
          traffic_limited: this.profile.traffic_limited,
          traffic_limit_value_mb:
            this.profile.traffic_limit_value_mb * this.limit_factor,
          ip_pool: this.profile.ip_pool,
          allowed_nases: this.allowed_nases,
          allowed_admins: this.allowed_admins,
          shared: this.profile.shared,
          custom_attributes: this.profile.custom_attributes,
          resellers_allowed_sessions: this.profile.resellers_allowed_sessions,
          has_open_speed: this.profile.has_open_speed,
          open_start_hour: this.profile.open_start_hour,
          open_stop_hour: this.profile.open_stop_hour,
          open_upload_rate_mb: this.profile.open_upload_rate_mb,
          open_download_rate_mb: this.profile.open_download_rate_mb,
          shared_download_rate_kb: this.profile.shared_download_rate_kb,
          shared_upload_rate_kb: this.profile.shared_upload_rate_kb,
          has_expiration_profile: this.profile.has_expiration_profile,
          expiration_profile_speed:
            this.profile.expiration_profile_speed *
            this.expiration_rate_limit_factor,
          expiration_profile_message: this.profile.expiration_profile_message,
          app_allowed: this.profile.app_allowed,
        });
        if (response.status == 200) {
          this.$store.commit("changeKey");
          this.$router.push({
            path: "/profiles",
            query: { success: "success" },
          });
        }
      } else {
        const response = await axios.post("/profiles", {
          name: this.profile.name,
          service: this.profile.service,
          type: this.profile.type,
          enabled: this.profile.enabled,
          price: this.profile.price,
          download_rate_kb:
            this.profile.download_rate_kb * this.download_rate_limit_factor,
          upload_rate_kb:
            this.profile.upload_rate_kb * this.upload_rate_limit_factor,
          has_expiration: this.profile.has_expiration,
          fixed_expiration: this.profile.fixed_expiration,
          expiration_unit: this.profile.expiration_unit,
          expiration_value: this.profile.expiration_value,
          traffic_limited: this.profile.traffic_limited,
          traffic_limit_value_mb:
            this.profile.traffic_limit_value_mb * this.limit_factor,
          ip_pool: this.profile.ip_pool,
          allowed_nases: this.allowed_nases,
          allowed_admins: this.allowed_admins,
          shared: this.profile.shared,
          custom_attributes: this.profile.custom_attributes,
          resellers_allowed_sessions: this.profile.resellers_allowed_sessions,
          has_open_speed: this.profile.has_open_speed,
          open_start_hour: this.profile.open_start_hour,
          open_stop_hour: this.profile.open_stop_hour,
          open_upload_rate_mb: this.profile.open_upload_rate_mb,
          open_download_rate_mb: this.profile.open_download_rate_mb,
          shared_download_rate_kb: this.profile.shared_download_rate_kb,
          shared_upload_rate_kb: this.profile.shared_upload_rate_kb,
          has_expiration_profile: this.profile.has_expiration_profile,
          expiration_profile_speed:
            this.profile.expiration_profile_speed *
            this.expiration_rate_limit_factor,
          expiration_profile_message: this.profile.expiration_profile_message,
          app_allowed: this.profile.app_allowed,
        });
        if (response.status == 200) {
          this.$store.commit("changeKey");
          this.$router.push({
            path: "/profiles",
            query: { success: "success" },
          });
        } else if (response.status == 210) {
          this.errors.name = this.$t("errors.profiles.name_taken");
          this.validated = false;
        }
      }
      this.loading = false;
    },
    async fetchProfile(id) {
      const response = await axios.get(`profiles/${id}`);
      if (response.status == 200) {
        this.profile = response.data.data;
        console.log(this.profile);
        if (this.hasPrivilege("nases_view_index_nases")) {
          this.profile.allowed_nases.forEach((element) => {
            this.allowed_nases.push(element.id);
          });
        }
        if (
          this.hasPrivilege("admins_view_index_admins") ||
          this.hasPrivilege("admins_view_search_admins")
        ) {
          this.profile.allowed_admins.forEach((element) => {
            this.allowed_admins.push(element.id);
          });
        }
        if (this.profile.download_rate_kb > 1023) {
          this.profile.download_rate_kb = this.profile.download_rate_kb / 1024;
        } else {
          this.download_rate_limit_factor = 1;
        }
        if (this.profile.upload_rate_kb > 1023) {
          this.profile.upload_rate_kb = this.profile.upload_rate_kb / 1024;
        } else {
          this.upload_rate_limit_factor = 1;
        }
        if (this.profile.expiration_profile_speed > 1023) {
          this.profile.expiration_profile_speed =
            this.profile.expiration_profile_speed / 1024;
        } else {
          this.expiration_rate_limit_factor = 1;
        }
      }
      if (this.profile.ip_pool) {
        this.ip_pool_enabled = true;
        this.fetch_pools();
        if (this.pools.length == 0) {
          this.pools.push({ id: "-1", name: this.profile.ip_pool });
        }
      }
      if (this.profile.download_rate_kb < 0) this.unlimited_speed = true;
    },
    async fetchNases() {
      const response = await axios.get("nases?itr=true&rows=10000000");
      response.data.data.forEach((element) => {
        this.nases.push({ value: element.id, label: element.shortname });
      });
    },
    async fetchAdmins() {
      const response = await axios.get(
        "admins?itr=true&type=resellers_admins&rows=10000000"
      );
      response.data.data.forEach((element) => {
        this.admins.push({ value: element.id, label: element.email });
      });
    },
    async fetch_pools() {
      this.loading = true;
      const response = await axios.get(`nases/pools/${this.allowed_nases}`);
      try {
        this.pools = response.data;
      } catch (error) {}
      this.loading = false;
    },
    toggleOpenSpeed() {
      if (
        !this.profile.has_open_speed &&
        (this.profile.shared || this.profile.custom_attributes != "")
      ) {
        this.showOpenDialog = true;
      } else if (!this.profile.has_open_speed) {
        this.profile.has_open_speed = true;
      } else {
        this.profile.has_open_speed = false;
      }
    },
    validate() {
      const onlyNumbers = new RegExp("^[0-9]+$");
      this.validated = true;
      this.errors.name = null;
      this.errors.download = null;
      this.errors.upload = null;
      this.errors.nas = null;
      this.errors.shared_upload = null;
      this.errors.shared_download = null;
      this.errors.expiration_profile_speed = null;
      if (this.profile.name == "") {
        this.errors.name = this.$t("errors.profiles.name_empty");
        this.validated = false;
      }
      if (this.profile.upload_rate_kb == 0 && !this.unlimited_speed) {
        this.errors.upload = this.$t("errors.profiles.speed_0");
        this.validated = false;
      }
      if (!onlyNumbers.test(this.profile.upload_rate_kb)) {
        this.errors.upload = this.$t("errors.profiles.not_number");
        this.validated = false;
      }
      if (this.profile.download_rate_kb == 0 && !this.unlimited_speed) {
        this.errors.download = this.$t("errors.profiles.speed_0");
        this.validated = false;
      }
      if (!onlyNumbers.test(this.profile.download_rate_kb)) {
        this.errors.download = this.$t("errors.profiles.not_number");
        this.validated = false;
      }
      if (this.allowed_nases.length < 1) {
        this.errors.nas = this.$t("errors.profiles.nases_1");
        this.validated = false;
      }
      if (this.profile.shared_download_rate_kb == 0 && this.profile.shared) {
        this.errors.shared_download = this.$t("errors.profiles.speed_0");
        this.validated = false;
      }
      if (!onlyNumbers.test(this.profile.shared_download_rate_kb)) {
        this.errors.shared_download = this.$t("errors.profiles.not_number");
        this.validated = false;
      }
      if (this.profile.shared_upload_rate_kb == 0 && this.profile.shared) {
        this.errors.shared_upload = this.$t("errors.profiles.speed_0");
        this.validated = false;
      }
      if (!onlyNumbers.test(this.profile.shared_upload_rate_kb)) {
        this.errors.shared_upload = this.$t("errors.profiles.not_number");
        this.validated = false;
      }
      if (!onlyNumbers.test(this.profile.expiration_profile_speed)) {
        this.errors.expiration_profile_speed = this.$t(
          "errors.profiles.not_number"
        );
        this.validated = false;
      }
    },
    changeProfileTrafficLimit(value) {
      this.profile.traffic_limit_value_mb = value;
    },
  },
};
</script>
